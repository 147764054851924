import { ActionButton } from "../model/actionButton";
import { ButtonSection } from "../model/buttonSection";

export const formatSection = new ButtonSection("Format", [
    new ActionButton("UPPERCASE", function (input) {
        return input.toUpperCase()
    }),
    new ActionButton("lowercase", function (input) {
        return input.toLowerCase()
    }),
    new ActionButton("Decode URL", function (input) {
        return decodeURI(input)
    }),
    new ActionButton("Encode URL", function (input) {
        return encodeURI(input)
    }),
    new ActionButton("Text to branch name", function (input) {
        return input.replace(/ /g, "-").replace(/"/g, "").replace(/[-]+/g, "-")
    })
]);
