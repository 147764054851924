import { ActionButton } from "../model/actionButton";
import { ButtonSection } from "../model/buttonSection";
import { Utils } from "../utils/utils";

export const filterSection = new ButtonSection("Filter", [
    new ActionButton("Remove duplicates", function (input) {
        return Utils.doOnLines(input, (lines) => {
            return lines.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t === value
                ))
            )
        })
    }),
    new ActionButton("Remove empty lines", function (input) {
        return Utils.doOnLines(input, (lines) => {
            return lines.filter((value, index, self) =>
                value && !/^\s*$/.test(value)
            )
        })
    })
]);
