import "./styles.css";
import { filterSection } from "./transformations/filter";
import { formatSection } from "./transformations/format";
import { sortSection } from "./transformations/sort";
import { Utils } from "./utils/utils";

const sections = [sortSection, filterSection, formatSection];

const toolsColumns = document.getElementById("tools");
const textArea = document.getElementById("inputText") as HTMLInputElement | null;

const searchField = document.getElementById("searchField") as HTMLInputElement | null;
searchField?.addEventListener("input", ((event) => loadSections()));
function loadSections() {
  toolsColumns?.replaceChildren();
  sections.forEach((section) => {
    const title = document.createElement("h2");
    title.innerText = section.name;
    title.classList.add("subtitle", "mb-2");
    let column = toolsColumns?.appendChild(Utils.wrapInDivWithClassList(title, ["column"]));
    section.buttons
      .filter((value, index, self) =>
        value.name.toLowerCase().includes(searchField?.value.toLowerCase() ?? "")
      )
      .forEach((actionButton) => {
        const button = document.createElement("button");
        button.innerText = actionButton.name;
        button.classList.add("button", "is-rounded", "mb-2");
        button.addEventListener("click", function handleClick() {
          if (textArea == null) return;
          const text = textArea.value;
          textArea.value = actionButton.action(text);
        });
        const div = document.createElement("div");
        div.appendChild(button);
        column?.appendChild(div);
      });
  });
}

loadSections()