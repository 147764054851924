import { ActionButton } from "./actionButton";

export class ButtonSection {
  name: string;
  buttons: ActionButton[];

  constructor(name: string, buttons: ActionButton[]) {
    this.name = name;
    this.buttons = buttons;
  }
}
