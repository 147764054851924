export class Utils {
    static doOnLines(text: string, process: (lines: string[]) => (string[])) {
        return process(text.split("\n")).join("\n")
    }

    static wrapInDivWithClassList(element: HTMLElement, classList: string[]) {
        let div = document.createElement('div');
        classList.forEach(klass => {
            div.classList.add(klass)
        });
        div.append(element);
        return div
    }
}