import { ActionButton } from "../model/actionButton";
import { ButtonSection } from "../model/buttonSection";

export const sortSection = new ButtonSection("Sort", [
  new ActionButton("By name", function (input) {
    return input?.split("\n").sort().join("\n");
  }),
  new ActionButton("By length", function (input) {
    return input
      ?.split("\n")
      .sort(function (a, b) {
        return a.length - b.length;
      })
      .join("\n");
  })
]);
